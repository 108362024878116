.faqs h5{
    text-align: center;
    color: green;
}

.horse {
    height: 430px;
    width: 100vw;
    object-fit: cover;
}

.intro {
    font-weight: 700;
    padding: 15px;
    text-align: justify;
}