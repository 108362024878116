.trademark{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: justify;
    font-weight: 500px;
    color:rgb(67, 35, 128);
    text-decoration: underline;
}
.slogan{
    position: absolute;
    top: 480px;
    left: 50px;
    z-index: 100000000;
}

.outro2{
    padding: 20px;
    font-weight: 600;
}

.travelstuffimg{
    position: absolute;
    object-fit: cover;
    opacity: 0.156;
}
.outro4{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    font-weight: 600;
}
.intro{
    font-weight: 700;
}

.Testimonials{
    text-decoration: none;
    color: white;
    
}
.TestimonialsContainer {
    display: flex;
    justify-content: space-between;
    margin: 30px;
}

.is-visible {
    /*position: absolute;
    top: 0;
    left: 0;*/
    animation: moveVehicle 16s ease-in-out 1;
    animation-fill-mode: forwards;

}

.outro{
    text-align: justify;
}
@keyframes moveVehicle {
    0% {
      transform: translateX(0);

    }
    
    100% {
      transform: translateX(100vw);
    }
  }

.Home {
    background-color: #f2f6f8;
}
.leftside {
    padding: 10px;
    flex: 2;
}
.rightside {
    padding: 10px;
    flex: 2;
    background-color:#E9F2F2;
    border-radius: 4px;
}
.outro1{
    display: flex;
    align-items: center;

}

.outro3{
    display: flex;
    align-items: center;
    gap: 30px;
}

.outro3 article{
    padding: 80px;
    font-weight: 700;
}
.outro3img{
    width: 800px;
    margin-right: 25px;
}
.outro1 article{
    font-weight: 700;
    padding: 20px;
}
.travel1svg{
    width: 1400px;
    height: 480px;
}

.PartnersContainer h4{
    display: flex;
    justify-content: center;
}

.PartnersImages {
    display: flex;
}

.swiper-button-next,
.swiper-button-prev {
  display: none;
}
.swiper-pagination-bullet {
    background-color:#486572;
 }
 
 .testimonialsButton {
    background-color: #486572 !important;
 } 
 .sunrise {
    padding: 7px;
 }
.intro {
    text-align: justify;
}
@media screen and (max-width: 768px) {
    .TestimonialsContainer{
        flex-direction: column;
    }

    .vehicleContainer,
    .is-visible {
        overflow: hidden;
    }

    .outro1{
        flex-wrap: wrap;
    }
    .outro3{
        flex-wrap: wrap;
    }
    .outro3 article{
        padding: 20px;
    }

    .blackguy{
        width: 100vw;
        max-height: 600px;
    }
    .slogan{
        position: absolute;
        left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}