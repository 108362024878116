

.accordionTitle{
    background-color: rgb(223, 239, 245);
    padding: 20px;
    cursor: pointer;
    margin: 4px;
    text-align: center;
    border-radius: 0px;
}

.accordionContent {
    max-height: 0;
    display:none;
    overflow: hidden;
    transition: max-height 3s ease-out;
}

  
.accordionContent.active {
    max-height: 400px;
    transition: max-height 3s ease-in;
    display: block;
    padding: 20px;
    color:rgb(4, 32, 43);
    background-color: rgb(122, 80, 29, 0.5)
  }
  