.destinations h4{
    text-align: center;
}
.faqs{
    text-align: center;
}
.faqs h5 {
    color: green
}

.overview {
    padding: 10px;
}

.safarisLandingPhoto {
    width: 100vw;
}

.passportgif{
    width: 30px;
    height: 30px;
    color: #f2f6f8;
}

.destinationsh4{
    display: flex;
    justify-content: center;
    align-items: center;
}

.mara {
    display: flex;
    align-items: center;
    padding: 10px;
    flex-wrap: nowrap;
}
.mara img {
    border-radius: 6px;
    width: 45vw;
}
.mara article {
    text-align: justify;
}
.wildlifeSafari {
    background-color: #f2f6f8;
}
.Amboseli {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
    align-items: center;
}
.Amboseli img {
    width: 45vw;
    border-radius: 6px;
}

.Samburu {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Samburu img {
    border-radius: 6px;
    width: 45vw;
}

.nairobi {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    text-align: justify;
}
.nairobi img {
    width: 45vw;
    border-radius: 6px;
}

.safarisLandingPhoto img{
    object-fit: cover;
    height: 450px;
    width: 100vw;
}


@media screen and (max-width: 768px) {
    .nairobi{
        flex-wrap: wrap;

    }
    .nairobi img{
        width: 100vw;
    }
    .mara img {
        width: 100vw;
    }
    .Samburu img{
        width: 100vw;
    }
    .Amboseli img{
        width: 100vw;
    }
    .Amboseli{
        flex-wrap: wrap;
    }
    .mara{
        flex-wrap: wrap;
    }
    .Samburu {
        flex-wrap: wrap;
    }
    .safarisLandingPhoto img{
        height: auto;
    }
}