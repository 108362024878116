.blog-feed {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
  }
  
  .blog-post {
    width: 100%;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 2px 2px #ccc;
  }
  
  .blog-title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .blog-date {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .blog-content {
    font-size: 16px;
    line-height: 1.4;
  }
  
  @media (min-width: 768px) {
    .blog-post {
      width: calc(50% - 10px);
    }
  }
  
  @media (min-width: 992px) {
    .blog-post {
      width: calc(33.33% - 10px);
    }
  }
  
  