.cta {
    display: flex;
    justify-content: space-between;
    background-color: #486572;

}

.time {
    font-size: 12px;
}
.wh{
    font-size: 13px;
}
.contact{
    font-size: 13px;
}
.right span{
    color: aliceblue;
}
.left span{
    color: aliceblue;
}