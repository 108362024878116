form {
    width: 50%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 2px 2px 5px #ddd;
  }

  .contactHome{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75vh;
  }

  .response{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75vh;
  }

  .loading{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75vh;
  }
  .p1{
    display: flex;
    justify-content: center;
  }
  
  .input-text,
  .input-email,
  .input-textarea {
    width: 100% !important;
    padding: 10px !important;
    margin: 10px 0 !important;
    border: 1px solid #ccc !important;
    border-radius: 5px !important;
  }
  
  .input-submit {
    width: 100% !important;
    padding: 10px !important;
    background-color: #486572 !important;
    color: white !important;
    border: none !important;
    border-radius: 5px !important;
    cursor: pointer !important;
  }
  
  @media (max-width:768px) {
    form {
      width: 100%;
    }
  }
  