.servicesh4 {
    text-align: center;
}

.interested {
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
}

.introservices, .offer, .interested{
    padding: 30px;
    font-weight: 400;
}

.interested button{
    border: none;
    padding: 16px;
    border-radius: 6px;
    background-color: rgb(122, 80, 29);
    cursor: pointer;
}