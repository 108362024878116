.cookie-banner {
   position: fixed;
   bottom: 0;
   left: 0;
   right: 0;
   background-color: #f2f2f2;
   padding: 1em;
   text-align: center;
   z-index: 10;
   transition: opacity 10s ease-in-out;
}