.mv {
    display: flex;
    justify-content: space-around;
    margin: 8px;
}

.aboutusl{
    height: 2px;
    
}
.aboutusl img{
    object-fit: cover;
    width: 100%;
    height: 1000px;
    opacity: 0.156;
}

/*.mvv {
    background-image: url(../Images/Aboutbackground.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}*/

.aboutcontent {
    display: flex;
    align-items: center;
    padding: 5px;
}
.aboutcontent img{
     border-radius: 5px;
     object-fit: contain;
}
.aboutcontent h5 {
    align-items: center;
    text-align: center;
    text-decoration: underline;
}

.introWho {
    text-align: justify;
}

.values {
    display: flex;
    justify-content:center;
    margin: 8px;
}

.values h3 {
    display: flex;
    justify-content: center;
}
.mv h3 {
    display: flex;
    justify-content: center;
}
.image{
    object-fit: contain;
}

@media (max-width: 768px) {
    .mv {
        flex-direction: column;
    }
    .aboutcontent {
        flex-direction: column-reverse;
    }
    .aboutcontent img{
        object-fit: fill;
    }    
}