.container1 {
    display: flex;
    padding: 10px;
    margin: 5px;
    justify-content: space-between;
}

.container2 {
    display: flex;
    padding: 10px;
    justify-content: space-evenly;
}

.font {
    font-weight: 900;
    font-size: 32px;
    margin-top: 4px;
    color: rgb(46, 106, 124);
    text-align: center;
}

@media  screen and (max-width: 768px) {
    .container1{
        flex-direction: column;
        justify-content: space-between;
    }

    .container2{
        flex-direction: column;
    }
}