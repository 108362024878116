.travelItinerary {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.travel{
    display: flex;
    justify-content: center;
}

.toppic {
    height: 400px;
}

.travelItinerary button {
    padding: 16px;
    border: none;
    border-radius: 12px;
    background-color: rgb(122, 80, 29);
    cursor: pointer;
}
.travelItinerary button:hover {
    opacity: 0.8;
}
.toppic img{
    width: 100vw;
    height: 100%;
    object-fit: cover;
}

.toparticle{
    padding: 10px;
    text-align: justify;
}

.adventure {

    background-color: #f2f6f8;
}

.title{
    text-align: center;
}
